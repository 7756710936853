import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from "@auth/Auth.duck.js";
import dashboard from "@dashboard/dashboard.duck";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: auth,
  dashboard: dashboard
})
export default createRootReducer