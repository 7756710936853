import React, { Suspense } from "react";
import ReactDOM from 'react-dom'; // react-router v4/v5
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import store, { history } from "@store/store";
import * as serviceWorker from '@src/serviceWorker';
import App from "./App";
import { GoogleOAuthProvider } from '@react-oauth/google';

import "@styles/reset.css";

//Allow <Link> to use anchors #
history.listen(location => {
    const { hash } = location;
    if (hash !== '') {
        setTimeout(
            () => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({block: 'center'});
                }
            },
            0
        );
    }
});

const Root = () => (
  <Provider store={store} >
    <GoogleOAuthProvider clientId="566745555725-6tdgf3kkttn23bs9qbte9hcartak0l4g.apps.googleusercontent.com">
      <ConnectedRouter history={history} >
        <Suspense fallback={<div/>}>
          <App />
        </Suspense>
      </ConnectedRouter>
    </GoogleOAuthProvider>;
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
