import React, { useContext, useEffect, useState } from 'react'
import styles from './StartForms.module.scss'
import { Button, Icon, Upload, message } from 'antd'
import StartFormsImage from '../../../graphics/startformsImage.jpg'
import { handleChange, handleChangeRenta } from '../../../utils/constants'
import Loader from '../../common/loader/Loader'
import { ProfileContext } from './NewProfile'
import { getInfoExtractedDeclaracionRenta, getInfoExtractedDocumentoIdentidad } from '../../../utils/nuevoFlujo'
import './general.css'

const StartForms = ({ setStartForms }) => {
  const { valuesProfile, setValuesProfile, props } = useContext(ProfileContext);
  const [textLoading, setTextLoading] = useState('Estamos utilizando inteligencia artifical para extraer información de tus documentos, ¿Genial, no?')
  const { id:userId, documento_identidad_status, documento_ingresos_1_status } = props.auth.user.User;
  const { declara_renta } = valuesProfile.financialData
  const [state, setState] = useState({
    loading: false,
    identificacionDoc: false,
    ingresosDoc: false,
  })
  const modifyStatusesDocsLocal = (doc) => {
    return doc === "faltante" ? false : true
  }
  let timer
  const changeTextLoading = () => {
    setTextLoading('El proceso está tardando más de lo esperado, pero no te preocupes, estamos aquí contigo')
  }
  useEffect(() => {
    if(state.loading){
      timer = setTimeout(changeTextLoading, 30000)
    } else {
      clearTimeout(timer)
      timer = null
    }
  }, [ state.loading ])
  useEffect(() => {
    setState({
      ...state, 
      identificacionDoc: modifyStatusesDocsLocal(documento_identidad_status),
      ingresosDoc: modifyStatusesDocsLocal(documento_ingresos_1_status)
    })
  }, [identificacionDoc, ingresosDoc])
  const { identificacionDoc, loading, ingresosDoc } = state
  let colorValidation = (identificacionDoc === true && declara_renta !== undefined && ingresosDoc === true)
  const checkDocuments = () => {
    if(identificacionDoc === false || declara_renta === null || ingresosDoc === false) {
      message.error("Debes completar los pasos para continuar")
      return
    }
    setStartForms(true)
  }
  const getUploadDocument = (field, statusDocLocal, statusDocCMS) => {
    const uploadProps = {
      onStart: (e) => {
        setState({
          ...state,
          loading: true
        })
      },
      onError: (e) => {
        message.error('Hubo un error cargando el archivo, intenta de nuevo.');
      },
      onSuccess: (e) => {
        if(field === "documento_identidad") getInfoExtractedDocumentoIdentidad(e, valuesProfile, setValuesProfile, state, setState)
        if(field === "documento_ingresos_1") getInfoExtractedDeclaracionRenta(e, valuesProfile, setValuesProfile, state, setState)
        message.success('Documento cargado con éxito')
      },
      accept: ".jpg, .pdf, .png",
      method: "POST",
      action: `${process.env.REACT_APP_EXTRACT_URL}${userId}/${field}`,
      headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_DOCUMENTS }
    };
    return (
      <>
        {
          statusDocCMS === "faltante" ? (
            <Upload 
              {...uploadProps} 
              data={{ field: field }} 
              showUploadList={false}
            >
              <Button
                style={{ fontSize: "13px" }}
                disabled={statusDocLocal}
              >
                <Icon type='upload' /> { statusDocLocal ? 'Archivo cargado' : 'Cargar archivo' }
              </Button>
            </Upload>
          ) : statusDocCMS === "validacion" ? (
            <div className='ant-document-validacion'>
              <Icon type='lock' /> Validación
            </div>
          ) : (
            <div className='ant-document-aprobado'>
              <Icon type='check' /> Aprobado
            </div>
          )
        }
      </>
    )
  }
  return (
    <>
      <section className={`${styles.container} profileContent`}>
        {
          loading === true && (
            <div className={styles.loading}>
              <Loader />
              <p className={styles.text_loading}>{textLoading}</p>
            </div>
          )
        }
        <div className={styles.left_content}>
          <img src={StartFormsImage} alt="start form image" className={styles.left_image}/>
        </div>
        <div className={styles.right_content}>
          <h1 className={styles.title}>¡Un paso a la vez, empecemos con los siguientes documentos!</h1>
          <p>¿Sabías que?</p>
          <p>Usamos inteligencia artificial para obtener data de los documentos que subes para evitar que digites información adicional</p>
          <ul className={styles.documents}>
            <li className={styles.document}>
                <p>¿Declaras renta?</p>
                <div className={styles.declara_renta}>
                  <div className={styles.declara_renta_item}>
                    <p>Sí</p>
                    <input type="radio" name='declara_renta' id='declaraRentaSi' checked={declara_renta === true} value='true' onChange={(e) => handleChangeRenta(e, valuesProfile, setValuesProfile, 'financialData')} />
                  </div>
                  <div className={styles.declara_renta_item}>
                    <p>No</p>
                    <input type="radio" name='declara_renta' id='declaraRentaNo' checked={declara_renta === false} value='false' onChange={(e) => handleChangeRenta(e, valuesProfile, setValuesProfile, 'financialData')} />
                  </div>
                </div>
            </li>
            <li className={styles.document}>
              <p>Documento de identidad</p>
              {
                getUploadDocument("documento_identidad", identificacionDoc, documento_identidad_status)
              }
            </li>
            <li className={styles.document}>
              {
                declara_renta === true ? (<p>Declaración de renta</p>) : <p>Extractos bancarios</p>
              }
              {
                getUploadDocument("documento_ingresos_1", ingresosDoc, documento_ingresos_1_status)
              } 
            </li>
          </ul>
          <div className={styles.btn_container}>
            <button 
              type='primary' 
              className={`${styles.btn_start} ${colorValidation && 'startForms'}`} 
              onClick={() => checkDocuments()}
              >Comenzar
            </button>
          </div>
        </div>
      </section>
    </>
  )
}

export default StartForms