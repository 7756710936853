import React, { useState } from 'react'
import styles from './TwoFaAuth.module.scss'
import TwoFaImage from '../../../graphics/twofa_image.png'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch } from 'react-redux';
import { loginTwoFaCode } from '../Auth.duck';
import { message } from 'antd';

const TwoFaAuth = () => {
  const dispatch = useDispatch()
  const [inputsRefs, setInputsRefs] = useState({
    input1: React.createRef(),
    input2: React.createRef(),
    input3: React.createRef(),
    input4: React.createRef(),
    input5: React.createRef(),
    input6: React.createRef()
  })
  const { twofa: token } = useParams();
  let code = ['0', '0', '0', '0', '0', '0']
  const onPaste = (e) => {
    e.preventDefault();
    const codePegado = e.clipboardData.getData('text');
    const codeSplit = codePegado.split('');
    if (codeSplit.length === code.length) {
      code = codeSplit;
      for (let i = 0; i < code.length; i++) {
        inputsRefs[`input${i + 1}`].current.value = code[i];
      }
    } else {
      message.error('Ingresa un código de 6 dígitos');
    }
  }
  const modifyCode = (e, index) => {
    code[index] = e.target.value
  }
  let history = useHistory();
  const validateCode = (e) => {
    if(code.join('') === "000000"){
      message.error("Ingresa un código a validar")
    }
    e.preventDefault()
    const data = {
      User: {
        code: code.join(''),
        token
      }
    }
    dispatch(loginTwoFaCode(data)).then((login) => {
      if (login && login.response.success) {
        history.push('/dashboard')
        message.success('Bienvenido')
      } else {
        message.error('Código no válido')
      }
    })
  }
  const handleKeyDown = (event, inputName) => {
    if (event.key === 'ArrowRight') {
      const nextInput = `input${(parseInt(inputName.slice(-1)) % 6) + 1}`;
      inputsRefs[nextInput].current.focus();
    } else if (event.key === 'ArrowLeft') {
      const prevInput = `input${(parseInt(inputName.slice(-1)) + 4) % 6 + 1}`;
      inputsRefs[prevInput].current.focus();
    }
  };
  const inputFields = [];
  const returnInputs = () => {
    for (let i = 1; i <= 6; i++) {
      const inputName = `input${i}`;
      const inputOnchange = i - 1
      inputFields.push(
        <input
          onPaste={(e) => onPaste(e)}
          type="text"
          maxLength={1}
          className={styles.input}
          onChange={(e) => modifyCode(e, inputOnchange)} 
          onKeyDown={(e) => handleKeyDown(e, inputName)} 
          ref={inputsRefs[inputName]}
          key={inputName}
        />
      );
    }
    return <div className={styles.inputs}>{inputFields}</div>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p className={styles.entry_title}>Doble factor de autenticación</p>
        <h1 className={styles.title}>Ingresa el código</h1>
        <div className={styles.container_image}>
          <img src={TwoFaImage} alt='imagen doble auth' className={styles.image}/>
        </div>
        <p className={styles.subtitle}>Proteger tu cuenta es nuestra prioridad. Por favor ingresa el código de 6 dígitos que hemos enviado al correo registrado</p>
        {
          returnInputs()
        }
        <button className={styles.button} onClick={(e) => validateCode(e)}>Verificar</button>
      </div>
    </div>
  )
}

export default TwoFaAuth
